@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_variables";
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_mixins";

/* = Primary Care Innov Home
----------------------------------------------------------*/

.panel-primary-care-home {
  .primary-care-content-top {
    padding-left: 0;
    padding-right: 0;
  }
  .primary-care-content-body {
    #body-2col-left {
      padding-left: 0;
    }
    #body-2col-right {
      padding-right: 0;
    }
  }

}
